@import "../util/variables";

.main {
  width: 100%;
  height: calc(100vh - 16rem);
  padding: 0 $sp-md-2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: $sp-avg;

  @media (max-width: 60.3125rem) {
    // height: auto;
    justify-content: bottom;
    gap: $sp-sm-1;
    padding: 0 $sp-sm-3;
  }
}
