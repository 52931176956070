@import "../../util/variables";

.container {
  width: 100%;
  height: calc(100vh - 16rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: $sp-sm-1 $sp-sm-3;
  gap: $sp-sm-3;
  min-width: 0;
  min-height: 0;
  transition: all 0.3s;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 123rem) {
    padding: $sp-sm-3 24rem;
  }

  @media (max-width: 35.3125rem) {
    grid-template-columns: 1fr;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: $sp-sm-3;
    margin-top: 2.4rem;
  }
}

.link {
  width: 100%;
  height: 100%;
  border-radius: $br-def;
  overflow: hidden;

  min-width: 0;
  filter: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.2));

  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    // transform: scale(1.02);
    & .cover {
      backdrop-filter: blur(0.5rem);
    }
    & .background {
      height: 145%;
    }
  }
}

.cover {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  backdrop-filter: blur(0.8rem);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-h1;
  font-weight: $fw-b;
  color: $color-wh;
  transition: all 0.3s;

  & span {
    filter: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.8));
  }
}

.discoverMovies {
  background: rgba($color-p-d, 0.4);
  background: linear-gradient(
    45deg,
    rgba($color-p-d, 0.8) 0%,
    rgba($color-p, 0.4) 75%,
    rgba($color-p-d, 0.6) 100%
  );
}

.discoverSeries {
  background: rgba($color-s-d, 0.4);
  background: linear-gradient(
    45deg,
    rgba($color-s-d, 0.8) 0%,
    rgba($color-s, 0.4) 75%,
    rgba($color-s-d, 0.6) 100%
  );
}

.topRatedMovies {
  background: rgba($color-p-d, 0.4);
  background: linear-gradient(
    45deg,
    rgba($color-p-d, 0.8) 0%,
    rgba($color-p, 0.4) 75%,
    rgba($color-p-d, 0.6) 100%
  );
}

.topRatedSeries {
  background: rgba($color-s-d, 0.4);
  background: linear-gradient(
    45deg,
    rgba($color-s-d, 0.8) 0%,
    rgba($color-s, 0.4) 75%,
    rgba($color-s-d, 0.6) 100%
  );
}

.background {
  height: 135%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.6s;
}
