@import "../../../util/variables";
@import "../../../util/mixins";

.signupForm {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $sp-md-1;
  width: 36rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fieldHolder {
  position: relative;
  width: 36rem;
}

.signupInput {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1rem;
  font-size: $fs-def;
  outline: 0.3rem solid rgba(115, 115, 115, 0.2);

  border: none;

  &:focus {
    outline: 0.3rem solid $color-gr-3;
  }

  &:not(:placeholder-shown) + .signupLabel,
  &:focus + .signupLabel {
    top: -35%;
    left: 0;
    font-style: normal;
    font-size: $fs-def;
    font-weight: $fw-sb;
    letter-spacing: $ls-tn;
    color: $color-wh;
    cursor: default;
  }
}

.signupLabel {
  position: absolute;
  left: 1rem;
  font-size: $fs-h3;
  font-style: italic;
  color: $color-gr-2;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.4s;
  cursor: text;
}

.actions {
  display: flex;
  gap: $sp-sm-3;
  align-items: center;
}

.btn {
  font-family: inherit;
  font-size: $fs-h3;
  padding: $sp-sm-1 $sp-sm-2;
  border-radius: $br-sm;
  border: none;
  cursor: pointer;
  text-decoration: none;

  & span {
    z-index: 1;
  }
}

.submitBtn {
  @include btnBackgroundColorChange(
    $color-s,
    $color-s-d,
    $color-wh,
    $color-wh,
    $br-sm
  );
}

.forgotBtn {
  @include btnBackgroundColorChange(
    none,
    $color-p-l-2,
    $color-p,
    $color-p,
    $br-sm
  );
}

.invalid {
  outline: 0.3rem solid rgba($color-t, 0.6);
}

.error {
  position: absolute;
  bottom: 5.5rem;
  padding: $sp-tn-2 $sp-tn;
  border-radius: $br-sm;
  left: 0;
  font-size: $fs-def;
  color: $color-t;
  background-color: rgba($color-t, 0.2);
}

.showPassBtn {
  position: absolute;
  top: 50%;
  right: $sp-tn;
  transform: translate(0, -50%);
  color: $color-gr-2;
  cursor: pointer;

  &:hover {
    color: $color-gr-3;
  }
}
