@import "../../util/variables";

.pagination {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media (max-width: 60.3125rem) {
    width: 30rem;
    margin-top: -5.4rem;
    margin-bottom: 2.7rem;
  }

  @media (max-width: 39.3rem) {
    margin-bottom: 4.2rem;
  }

  @media (max-width: 22.5rem) {
    gap: 0.8rem;
  }
}

.searchQueryTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 125%);
  font-size: $fs-sm;
  padding: $sp-tn-2 $sp-tn;
  border-radius: $br-def;
  background-color: rgba($color-wh, 0.4);

  @media (max-width: 60.3125rem) {
    top: 55%;
  }
}

.btn {
  border: none;
  background: none;
  font-style: inherit;
  font-size: $fs-def;
  width: $sp-sm-3;
  height: $sp-sm-3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $sp-sm-2-5;
  color: $color-wh;
  background-color: $color-p;
  cursor: pointer;

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span svg {
    width: 2.4rem;
  }

  &:disabled,
  &[disabled] {
    background-color: $color-gr-3;
    cursor: default;
  }
}

.currentPage {
  font-size: $fs-h1;
  font-weight: $fw-b;
  font-style: italic;
  color: $color-s;

  @media (max-width: 22.5rem) {
    font-size: $fs-h2;
  }
}
