// COLORS
$color-p: #10b981;
$color-p-d: #047857;
$color-p-l: #6ee7b7;
$color-p-l-2: #d1fae5;

$color-s: #0ea5e9;
$color-s-d: #0369a1;
$color-s-l: #7dd3fc;

$color-t: #f43f5e;
$color-t-d: #be123c;
$color-t-l: #fda4af;

$color-u: #f97316;
$color-u-d: #c2410c;
$color-u-l: #fdba74;

$color-bl: #171717;
$color-gr-4: #262626;
$color-gr-3: #404040;
$color-gr-2: #737373;
$color-gr-1: #d4d4d4;
$color-wh: #fafafa;

// FONT SIZES
$fs-h1: 3.6rem;
$fs-h2: 2.4rem;
$fs-h3: 2rem;
$fs-def: 1.6rem;
$fs-sm: 1.2rem;
$fs-sm-2: 1rem;
$fs-tn: 0.8rem;

// FONT WEIGHTS
$fw-def: 400;
$fw-md: 500;
$fw-sb: 600;
$fw-b: 700;

// LETTER SPACING
$ls-tn: 0.1rem;
$ls-sm: 0.25rem;
$ls-def: 0.5rem;
$ls-bg: 0.75rem;
$ls-hf: 1rem;

// BORDER-RADIUS
$br-tn: 0.5rem;
$br-sm: 1rem;
$br-def: 2rem;
$br-bg: 4rem;

// SPACING
$sp-hg: 12.8rem;
$sp-lrg: 8rem;

$sp-bg: 6.4rem;
$sp-md-3: 5.4rem;
$sp-md-2: 4.8rem;
$sp-md-1: 4rem;
$sp-avg: 3.2rem;
$sp-sm-3: 2.4rem;
$sp-sm-2-5: 2rem;
$sp-sm-2: 1.6rem;
$sp-sm-1: 1.2rem;
$sp-tn: 0.8rem;
$sp-tn-2: 0.4rem;
$sp-tn-3: 0.2rem;
