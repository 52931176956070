@import "../../util/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $sp-tn;
  height: 100%;
  width: 100%;
}

.header {
  align-self: flex-start;
  font-size: $fs-h1;
  font-style: italic;
  margin-top: $sp-sm-3;
}

.shadowContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.shadowTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4%;
  background: rgb(23, 23, 23);
  background: linear-gradient(
    180deg,
    rgba(23, 23, 23, 1) 5%,
    rgba(14, 165, 233, 0) 50%
  );
  mask: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 25%,
    rgba(14, 165, 233, 0) 100%
  );

  backdrop-filter: blur(0.5rem);

  z-index: 9999;
}

.shadowBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4%;
  background: rgb(23, 23, 23);
  background: linear-gradient(
    0deg,
    rgba(23, 23, 23, 1) 5%,
    rgba(14, 165, 233, 0) 50%
  );
  mask: linear-gradient(0deg, rgba(0, 0, 0, 1) 25%, rgba(14, 165, 233, 0) 100%);

  backdrop-filter: blur(0.5rem);

  z-index: 9999;
}

.list {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(16, 185, 129);
    background: linear-gradient(
      45deg,
      rgba(16, 185, 129, 1) 0%,
      rgba(14, 165, 233, 1) 100%
    );
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: loader1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: loader2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: loader2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: loader3 0.6s infinite;
    }
  }
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
