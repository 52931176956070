@import "../util/variables";
@import "../util/mixins";

.panelsContainer {
  width: 100%;
  height: calc(100vh - 22rem);
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $sp-md-2;

  @media (max-width: 60.3125rem) {
    flex-direction: column;
    gap: $sp-sm-2;
    height: calc(100vh - 18rem);
  }

  @media (max-width: 39.0625rem) {
    margin-top: -1rem;
    height: calc(100vh - 20rem);
  }

  // @media (max-width: 20rem) {
  //   height: auto;
  // }
}

.panel {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  border: 0.15rem inset rgba(255, 255, 255, 0.1);
  border-radius: $br-def;
  color: $color-bl;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  backface-visibility: hidden;
  filter: drop-shadow(0 0 0.4rem rgba(0, 0, 0, 0.4));

  @media (max-width: 60.3125rem) {
    padding: $sp-sm-3;
  }

  @media (max-width: 36.875rem) {
    padding: $sp-sm-2;
  }

  @media (max-width: 24.75rem) {
    padding: $sp-sm-1;
  }
}

.overflowPanel {
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100vh - 23.4rem);
}

.leftPanel {
  @include scrollbarStyling($color-p-d, $color-p, $color-p-d);
}

.rightPanel {
  @include scrollbarStyling($color-s-d, $color-s, $color-s-d);
}

.panelCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.98);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-h1;
  color: $color-wh;
  opacity: 1;
  transform: scale(1);
  backdrop-filter: blur(1rem);
  backface-visibility: hidden;
  visibility: visible;
  z-index: 9999;
  border-radius: $br-def;

  transition: all 0.4s;
  cursor: pointer;

  &:hover .leftCover {
    color: $color-p;
    transform: scale(1.1);
  }

  &:hover .rightCover {
    color: $color-s;
    transform: scale(1.1);
  }
}

.leftCover,
.rightCover {
  transition: all 0.4s;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}
