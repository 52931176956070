.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
  text-align: center;

  & h1 {
    font-size: 4.8rem;
    background: rgb(16, 185, 129);
    background: linear-gradient(
      90deg,
      rgba(16, 185, 129, 1) 0%,
      rgba(14, 165, 233, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & h2 {
    font-size: 3.6rem;
    margin-bottom: 1.6rem;
  }

  & p {
    font-size: 1.6rem;
    color: rgb(135, 135, 135);
  }
}
