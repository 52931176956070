@mixin btnBackgroundColorChange($clr-b-1, $clr-b-2, $clr-t-1, $clr-t-2, $br) {
  position: relative;
  // overflow: hidden;
  background-color: $clr-b-1;
  color: $clr-t-1;
  transition: all 0.3s;

  & * {
    position: relative;
    z-index: 1;
  }

  &::after {
    background-color: $clr-b-2;
    content: "";
    position: absolute;
    top: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem;
    right: -0.1rem;
    opacity: 0;
    border-radius: $br;

    // backface-visibility: hidden;

    transition: all 300ms;
  }

  &:hover {
    color: $clr-t-2;
    transform: scale(1.05);

    &::after {
      opacity: 1;
    }
  }
}

@mixin scrollbarStyling($clr-1, $clr-2, $clr-3) {
  &::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: none;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: $color-wh;
    background-image: -webkit-gradient(
      linear,
      40% 0%,
      75% 84%,
      from($clr-1),
      to($clr-2),
      color-stop(0.6, $clr-3)
    );
  }
}
